<template>
  <div class="wait">
      <van-image width="50%"  src="https://khome2.tuzuu.com/klfront/newlogo.png" />
     <div class="con-1">
        <div>很抱歉，目前预约人数较多</div>
        <div>预计排队10~20分钟，请稍等</div>
     </div>

     <div class="con-2">
        <div>您也可以联系客服为您解答</div>
        <van-image width="190" height="190" :src=" kf || 'https://khome2.tuzuu.com/klfront/kefu.png'" />
     </div>
  </div>
</template>

<script>
export default {
    name: "Wait",
    data(){
        return{
            kf:''
        }
    },
    created(){
        this.kf = this.$route.query.kf
    }
}
</script>

<style lang="less" scoped>
    .wait{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 50px;
        font-size: 13px;
    }
    .con-1{
        color: black;
        margin-bottom: 20px;
        box-sizing: border-box;
        line-height: 23px;
    }
    .con-2{
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        align-items: center;
    }

</style>>
